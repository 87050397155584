import React from 'react';
import { Alert } from 'reaxl';

const ExpiredDealerAlert = () => (
    <Alert
        show
        bsStyle="warning"
    >
        <span>
            <b>
                {'We\'re sorry, the dealer you were looking for is no longer available.'}
            </b>
        </span>
        <p>
            Here are other nearby dealerships.
        </p>
    </Alert>
);

export default ExpiredDealerAlert;
